<template>
  <div class="ap_cjactive d-flex flex-column">
    <div class="banner_group flex-shrink-0">
      <img
        src="~@/assets/images/active_page/cjactive/banner.png"
        alt=""
        class="banner"
      >
    </div>
    <div class="form_group bbox">
      <img
        src="~@/assets/images/active_page/cjactive/title.png"
        alt=""
        class="title"
      >
      <img
        src="~@/assets/images/active_page/cjactive/sawtooth.png"
        alt=""
        class="sawtooth"
      >
      <div class="input_group bbox">
        <div class="text">
          联系方式
        </div>
        <div class="hint">
          （便于后续惊喜联系）
        </div>
        <div class="input_box">
          <input
            type="text"
            class="input bbox"
            placeholder="请输入手机号码"
            maxlength="11"
            v-model="phone"
          >
          <img
            v-show="phone !== ''"
            src="~@/assets/images/active_page/cjactive/clear.png"
            alt=""
            class="clear"
            @click.stop="clearPhone()"
          >
        </div>
      </div>
      <div
        class="button d-flex align-center justify-center"
        :class="{ loading }"
        @click.stop="doSubmit()"
      >
        <div class="text">
          打开新世界传送门
        </div>
        <img
          v-show="loading"
          src="~@/assets/images/active_page/cjactive/loading.png"
          alt=""
          class="loading_icon"
        >
      </div>
    </div>
    <div
      v-if="showResult"
      class="mask d-flex align-center justify-center"
      :class="{ active: showMaskBg }"
    >
      <div
        v-if="maskType === 'qrcode'"
        class="pop_up qrcode_wrapper"
      >
        <div class="qrcode_group">
          <img
            src="~@/assets/images/active_page/cjactive/plane.png"
            alt=""
            class="plane"
          >
          <div class="title">
            传送进行中...
            <br>
            获取注册码 完成下载
          </div>
          <img
            src=""
            alt=""
            class="qrcode"
          >
        </div>
        <img
          src="~@/assets/images/active_page/cjactive/close.png"
          alt=""
          class="close"
          @click.stop="closeMask()"
        >
      </div>
      <div
        v-if="maskType === 'url'"
        class="pop_up url_wrapper"
      >
        <img
          src="~@/assets/images/active_page/cjactive/success.png"
          alt=""
          class="success"
        >
        <div class="text">
          传送进行中…
          <br>
          获取注册码 完成下载
        </div>
      </div>
      <div
        v-if="maskType === 'hint'"
        class="pop_up hint_wrapper"
      >
        <div class="hint_group">
          <div class="title">
            提交成功
          </div>
          <div
            class="hint bbox"
            v-text="hint"
          >
            链接未提供时期的文案@寅哥提供
            例如“提交成功，请于xx日于此下载”等
          </div>
          <div
            class="sure"
            @click.stop="closeMask()"
          >
            好的
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import regexp from '@/utils/regexp'
import global from '@/api/global'
export default {
  name: 'index',
  mounted(){
    document.title = 'MetaCJ元宇宙实践体验周入口'
    document.documentElement.style.fontSize = '13.333333vw'
    this.doCount()
    this.w_height = window.innerHeight
    let height = document.documentElement.clientHeight
    window.onresize = () => { // 在页面大小发生变化时调用
      // 把获取到的高度赋值给根div
      document.getElementById('views_active_page_plan_page').style.height = height + 'px'
    }
  },
  beforeDestroy(){
    document.documentElement.style.fontSize = '14px'
  },
  data() {
    return {
      showResult: false,
      showMaskBg: false,
      maskType: 'hint', // 'qrcode', 'url'
      hint: '链接未提供时期的文案@寅哥提供\n例如“提交成功，请于xx日于此下载”等',
      w_height: 0,
      phone: '',
      loading: false
    }
  },
  watch: {
    phone: function(val) {
      if(val){
        val = String(val)
        this.phone = val.replace(/[^\d^]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.')
      }
    }
  },
  methods: {
    closeMask() {
      this.showResult = false
    },
    handleFocus() {
      document.body.style.height = this.w_height + 'px'
    },
    clearPhone() {
      this.phone = ''
    },
    async doCount() {
      const { $route } = this
      const { query } = $route
      const { codeno } = query
      await global.doPost({
        url: '/projectH5/cjcount',
        data: {
          id: codeno
        }
      })
    },
    async doSubmit() {
      const { phone, $route } = this
      const { query } = $route
      const { codeno } = query
      const isRightPhone = regexp.phone.test(phone)
      console.log(isRightPhone)
      if (!isRightPhone) {
        this.$notice({
          desc: '请输入正确的手机号',
          mobile: true
        })
        return
      }
      if (this.loading) {
        return
      }
      this.loading = true
      const res = await global.doPost({
        url: '/projectH5/cjdownload',
        data: {
          phone,
          codeno
        }
      })
      this.loading = false
      const { message, contents } = res
      if (message === 'success') {
        const { flag, message, url } = contents
        if (Number(flag) === 0) {
          this.hint = message
          this.showMaskBg = true
          this.maskType = 'hint'
        }
        if (Number(flag) === 1) {
          this.showMaskBg = false
          this.maskType = 'url'
          if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
            setTimeout(() => {
              window.open(`http://${url}`, '_target')
            }, 2000)
          } else {
            setTimeout(() => {
              // window.open(url, '_target')
              location.href = url
            }, 2000)
          }
        }
        // if (Number(flag) === 0 ) {
        //   this.showMaskBg = true
        //   this.maskType = 'qrcode'
        // }
        this.showResult = true
      }
    }
  }
}
</script>

<style scoped lang="scss">
@keyframes myfirst
{
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}

@-webkit-keyframes myfirst /* Safari and Chrome */
{
  from {transform:rotate(0deg);}
  to {transform:rotate(360deg);}
}
.ap_cjactive{
  background-color: #6193F2;
  width: 100%;
  max-width: 960px;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  .banner_group{
    .banner{
      width: 100%;
      display: block;
    }
  }
  .form_group{
    width: 100%;
    padding: 0.78rem 0.6rem 0;
  }
  img.title{
    width: 5.27rem;
    margin: 0 auto 0.64rem;
    display: block;
  }
  img.sawtooth{
    width: 100%;
    display: block;
  }
  .input_group{
    width: 100%;
    background-color: #fff;
    color: #4482F4;
    text-align: center;
    padding: 0.56rem 0.4rem;
    margin-bottom: 0.8rem;
    .text{
      font-size: 20px;
      font-family: PingFangSC-Semibold, PingFang SC,sans-serif;
      font-weight: 600;
      margin-bottom: 4px;
    }
    .hint{
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC,sans-serif;
      font-weight: 400;
      margin-bottom: 0.5rem;
    }
    .input_box{
      width: 100%;
      position: relative;
    }
    .input{
      @include placeholder(#82929C);
      width: 100%;
      padding: 0 10px;
      line-height: 40px;
      background-color: #FAFAFA;
      border-radius: 4px;
      border: 1px solid #D5D9DB;
      color: #333;
      position: relative;
      z-index: 2;
      display: block;
      outline: none;
    }
    .clear{
      position: absolute;
      z-index: 3;
      width: 20px;
      height: 20px;
      top: 50%;
      transform: translateY(-50%);
      right: 10px;
    }
  }
  .button{
    @include transition;
    width: 100%;
    text-align: center;
    background: #EF5B11;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC,sans-serif;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
    height: 40px;
    .loading_icon{
      animation: myfirst 1.5s linear infinite ;
      width: 20px;
      heihgt: 20px;
      display: block;
    }
    &.loading{
      background-color: #B8B8B8;
      .text{
        display: none;
      }
    }
  }
  .mask{
    position: absolute;
    z-index: 99;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    &.active{
      background-color: rgba(0, 0, 0, 0.6);
    }
  }
  .qrcode_wrapper{
    width: 6rem;
    .qrcode_group{
      width: 100%;
      background-color: #fff;
      border-radius: 10px;
      position: relative;
      margin-bottom: 0.6rem;
      padding: {
        top: 1.14rem;
        bottom: 0.6rem;
      }
      .plane{
        position: absolute;
        top: -0.68rem;
        left: 50%;
        transform: translateX(-50%);
        width: 2.16rem;
      }
      .title{
        font-size: 18px;
        text-align: center;
        font-family: PingFangSC-Regular, PingFang SC,sans-serif;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        margin-bottom: 0.3rem;
      }
      .qrcode{
        display: block;
        width: 3rem;
        height: 3rem;
        margin: 0 auto;
      }
    }
    .close{
      width: 0.6rem;
      height: 0.6rem;
      margin: 0 auto;
      display: block;
    }
  }
  .url_wrapper{
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    width: 4rem;
    padding: 0.4rem 0;
    .success{
      width: 0.8rem;
      height: 0.8rem;
      display: block;
      margin: 0 auto;
    }
    .text{
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC,sans-serif;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 24px;
      text-align: center;
      margin-top: 0.2rem;
    }
  }
  .hint_wrapper{
    width: 6rem;
    border-radius: 10px;
    background-color: #fff;
    padding: {
      top: 0.5rem;
      bottom: 0.5rem;
    }
    text-align: center;
    .title{
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC,sans-serif;
      font-weight: 500;
      color: #333333;
      margin-bottom: 0.4rem;
    }
    .hint{
      width: 100%;
      padding: 0 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC,sans-serif;
      font-weight: 400;
      color: #333333;
      white-space: pre-line;
      word-break: break-all;
      margin-bottom: 0.5rem;
    }
    .sure{
      width: 2.4rem;
      text-align: center;
      font-size: 16px;
      line-height: 50px;
      border-radius: 4px;
      background-color: #EF5B11;
      color: #fff;
      margin: 0 auto;
    }
  }
}
</style>