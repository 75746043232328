var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ap_cjactive d-flex flex-column" }, [
    _vm._m(0),
    _c("div", { staticClass: "form_group bbox" }, [
      _c("img", {
        staticClass: "title",
        attrs: {
          src: require("@/assets/images/active_page/cjactive/title.png"),
          alt: "",
        },
      }),
      _c("img", {
        staticClass: "sawtooth",
        attrs: {
          src: require("@/assets/images/active_page/cjactive/sawtooth.png"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "input_group bbox" }, [
        _c("div", { staticClass: "text" }, [
          _vm._v("\n        联系方式\n      "),
        ]),
        _c("div", { staticClass: "hint" }, [
          _vm._v("\n        （便于后续惊喜联系）\n      "),
        ]),
        _c("div", { staticClass: "input_box" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.phone,
                expression: "phone",
              },
            ],
            staticClass: "input bbox",
            attrs: {
              type: "text",
              placeholder: "请输入手机号码",
              maxlength: "11",
            },
            domProps: { value: _vm.phone },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.phone = $event.target.value
              },
            },
          }),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.phone !== "",
                expression: "phone !== ''",
              },
            ],
            staticClass: "clear",
            attrs: {
              src: require("@/assets/images/active_page/cjactive/clear.png"),
              alt: "",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.clearPhone()
              },
            },
          }),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "button d-flex align-center justify-center",
          class: { loading: _vm.loading },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.doSubmit()
            },
          },
        },
        [
          _c("div", { staticClass: "text" }, [
            _vm._v("\n        打开新世界传送门\n      "),
          ]),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.loading,
                expression: "loading",
              },
            ],
            staticClass: "loading_icon",
            attrs: {
              src: require("@/assets/images/active_page/cjactive/loading.png"),
              alt: "",
            },
          }),
        ]
      ),
    ]),
    _vm.showResult
      ? _c(
          "div",
          {
            staticClass: "mask d-flex align-center justify-center",
            class: { active: _vm.showMaskBg },
          },
          [
            _vm.maskType === "qrcode"
              ? _c("div", { staticClass: "pop_up qrcode_wrapper" }, [
                  _vm._m(1),
                  _c("img", {
                    staticClass: "close",
                    attrs: {
                      src: require("@/assets/images/active_page/cjactive/close.png"),
                      alt: "",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.closeMask()
                      },
                    },
                  }),
                ])
              : _vm._e(),
            _vm.maskType === "url"
              ? _c("div", { staticClass: "pop_up url_wrapper" }, [
                  _c("img", {
                    staticClass: "success",
                    attrs: {
                      src: require("@/assets/images/active_page/cjactive/success.png"),
                      alt: "",
                    },
                  }),
                  _vm._m(2),
                ])
              : _vm._e(),
            _vm.maskType === "hint"
              ? _c("div", { staticClass: "pop_up hint_wrapper" }, [
                  _c("div", { staticClass: "hint_group" }, [
                    _c("div", { staticClass: "title" }, [
                      _vm._v("\n          提交成功\n        "),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "hint bbox",
                        domProps: { textContent: _vm._s(_vm.hint) },
                      },
                      [
                        _vm._v(
                          "\n          链接未提供时期的文案@寅哥提供\n          例如“提交成功，请于xx日于此下载”等\n        "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "sure",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.closeMask()
                          },
                        },
                      },
                      [_vm._v("\n          好的\n        ")]
                    ),
                  ]),
                ])
              : _vm._e(),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "banner_group flex-shrink-0" }, [
      _c("img", {
        staticClass: "banner",
        attrs: {
          src: require("@/assets/images/active_page/cjactive/banner.png"),
          alt: "",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "qrcode_group" }, [
      _c("img", {
        staticClass: "plane",
        attrs: {
          src: require("@/assets/images/active_page/cjactive/plane.png"),
          alt: "",
        },
      }),
      _c("div", { staticClass: "title" }, [
        _vm._v("\n          传送进行中...\n          "),
        _c("br"),
        _vm._v("\n          获取注册码 完成下载\n        "),
      ]),
      _c("img", { staticClass: "qrcode", attrs: { src: "", alt: "" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "text" }, [
      _vm._v("\n        传送进行中…\n        "),
      _c("br"),
      _vm._v("\n        获取注册码 完成下载\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }